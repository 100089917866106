import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/Logout',
    name: 'Logout',
    component: () => import('../views/LogoutView.vue'),
    meta: { requiresAuth: true}
  },
  {

    path: '/ForgottenPassword',
    name: 'ForgottenPassword',
    component: () => import('../views/ForgottenPassword.vue')

  },

   {
      path: '/UserStats/:userid',
      name: 'UserStats',
      component: () => import('../views/UserStats.vue'),
      props: true,
      meta: { requiresAuth: true}
  },
  
  {
    path: '/Editobjectif/:objectifid',
    name: 'EditObjectif',
    component: () => import('../views/EditObjectif.vue'),
    props: true,
    meta: { requiresAuth: true}
  },
  {
    path: '/Weekendwarning',
    name: 'WeekendWarning',
    component: () => import('../views/WeekendWarning.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/Weekend',
    name: 'Weekend',
    component: () => import('../views/WeekendView.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/Userprofil/:userId',
    name: 'UserProfil',
    component: () => import('../views/UserProfil.vue'),
    props: true,
    meta: { requiresAuth: true}
  },
  {
    path: '/BreakDates/:userId',
    name: 'BreakDates',
    component: () => import('../views/BreakDates.vue'),
    props: true,
    meta: { requiresAuth: true}
  },
  {
    path: '/EditPassword/:userId',
    name: 'EditPassword',
    component: () => import('../views/EditPassword.vue'),
    props: true,
    meta: { requiresAuth: true}
  },
  {
    path: '/EditMandates',
    name: 'EditMandates',
    component: () => import('../views/EditMandates.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/AddMandate',
    name: 'AddMandate',
    component: () => import('../views/AddMandate.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/EditMandatesType/:idaction',
    name: 'EditMandatesType',
    component: () => import('../views/EditMandatesType.vue'),
    props: true,
    meta: { requiresAuth: true}
  },
  {
    path: '/EditMandatesNumber/:idaction/:idtype',
    name: 'EditMandatesNumber',
    component: () => import('../views/EditMandatesNumber.vue'),
    props: true,
    meta: { requiresAuth: true}
  },
  {
    path: '/Management',
    name: 'Management',
    component: () => import('../views/ManagementView.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/ActionsOnUsers/:userId',
    name: 'ActionsOnUsers',
    component: () => import('../views/ActionsOnUsers.vue'),
    props: true,
    meta: { requiresAuth: true}
  },
  {
    path: '/AddUser',
    name: 'AddUser',
    component: () => import('../views/AddUser.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/UserComments/:userId',
    name: 'UserComments',
    component: () => import('../views/UserComments.vue'),
    props: true,
    meta: { requiresAuth: true}
  },
  {
    path: '/GlobalStatsMandates',
    name: 'GlobalStatsMandates',
    component: () => import('../views/GlobalStatsMandates.vue'),
    meta: { requiresAuth: true}
  },
  {
    path: '/UserObjectivesGoal/:userId',
    name: 'UserObjectivesGoal',
    component: () => import('../views/UserObjectivesGoal.vue'),
    props: true,
    meta: { requiresAuth: true}
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
