class StorageService {
    /**
     * Define LocalStorageKeys
     */
   
    storageKey = `${window.VUE_STORAGE_PREFIX}-terreetpierre`;
    getUser() {
      return JSON.parse(localStorage.getItem(this.storageKey));
    }
  
    update(data) {
        localStorage.setItem(this.storageKey, JSON.stringify(data));
    }
  
    reset() {
      localStorage.removeItem(this.storageKey);
    }
  
    getLocalAccessToken() {
      const user = this.getUser()
      return user?.token;
    }
  
    updateLocalAccessToken(token) {
      let user = JSON.parse(localStorage.getItem(this.storageKey));
      user.token = token;
      localStorage.setItem(this.storageKey, JSON.stringify(user));
    }
    updateLocalUserDatas(nom, prenom, poste){
      let user = JSON.parse(localStorage.getItem(this.storageKey));
      user.firstname = prenom;
      user.lastname = nom;
      user.poste = poste;
      localStorage.setItem(this.storageKey, JSON.stringify(user));
      return user
    }

    setUser(user) {
      localStorage.setItem(this.storageKey, JSON.stringify(user));
    }
  
    removeUser() {
      localStorage.removeItem(this.storageKey);
    }
  
    updateLocalUserImage(imageLink) {
      let user = JSON.parse(localStorage.getItem(this.storageKey));
      user.profilePicture = imageLink;
      localStorage.setItem(this.storageKey, JSON.stringify(user));
      return user
    }
  }
  
  export default new StorageService();