import StorageService from "./storage.service";
import TerreEtPierreApiService from "./terreetpierreapi.service";

class AuthService {
    async login(user) {
        try {
            let userResponse = await TerreEtPierreApiService.authenticate(user);
            StorageService.update(userResponse.data);
            return userResponse.data
        } catch (error) {
            console.log("Erreur while authenticating", error)
        }
        return null
    }
    async refreshToken(){
        return TerreEtPierreApiService.refresh()
    }
    logout() {
        StorageService.reset();
    }
}

export default new AuthService();




