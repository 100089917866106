<template>
  <div>
    <router-view />  
    <div  v-if="updateExists" >
      {{ $t('App.MajDispo') }}
      <button @click="refreshApp">{{ $t('App.MettreAJour') }}</button>
    </div>
  </div>
</template>


<script>
import update from './mixins/update'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import EventBus from "./common/Eventbus";

export default{
  async beforeMount (){
    var pjson = require('../package.json');
    this.$store.dispatch('navigation/version', pjson.version)
    if (window.VUE_ENABLE_DDOG) this.enableDatadog(pjson.version)
  },
  mixins: [update],
  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },
  beforeUnmount() {
    EventBus.remove("logout");
  },
  methods: {
     logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },

    enableDatadog(version) {

      datadogRum.init({
        applicationId: '1a7264ae-e901-4667-9f77-91dff42758a6',
        clientToken: 'pub5a131189f5499c3f8cbab9abadd8e621',
        site: 'datadoghq.eu',
        service:'terreetpierre-app',
        version: version,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel:'mask-user-input'
    });
      datadogLogs.setLoggerGlobalContext({ env:  window.VUE_DDOG_ENV })
      datadogRum.startSessionReplayRecording();
      }
    }
}
</script>

<style lang="scss"> 
  @import "scss/_mediaqueries.scss";
  @import "scss/_common-colors.scss";
  @import "scss/_common-styles.scss";
  @import "scss/_common-elements.scss";
  @import "scss/_fonts.scss";
  @import "scss/_simple-pages.scss";
  @import "scss/_pages.scss";
  @import "scss/_admin.scss";
  @import "scss/_normalize.scss";
</style>