
const initialState = {
    weeks: [],
    years:[]
}

export const stats = {
    namespaced: true,
    state: initialState,
    actions: {
        changeWeeks({commit}, weeks) {
            commit('weekChange', weeks);
            return Promise.resolve(weeks);
        },
    },

    mutations: {
        weekChange(state, weeks) {
            state.weeks = weeks
        }
    }
};
