import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import setupInterceptors from './services/setupInterceptors.js';
setupInterceptors(store);

// vérifier que la page est taguée dans le router comme nécessitant un user logué
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.state.auth.loggedIn) {
        next({ name: 'Login' })
      } else {
        next() // go to wherever I'm going
      }
    } else {
      next() // does not require auth, make sure to always call next()!
    }
})
  

createApp(App).use(store).use(router).mount('#app')
