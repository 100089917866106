import axiosInstance from "./api";
import storageService from "./storage.service";
import authService from "./auth.service";
import router from "@/router";

const setup = (store) => {
    axiosInstance.interceptors.request.use(
    (config) => {
      const token = storageService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if ( (originalConfig.url !== "/authentication/")  && err.response){
        console.log(originalConfig)
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          console.info("Token Expired, Refreshing");
          try {
            const responseRefresh = await authService.refreshToken()
            const newToken = responseRefresh.data.token;
            console.info("response refresh", responseRefresh)
            store.dispatch('auth/refreshToken', newToken);
            console.info("Token Refreshed Successfully", axiosInstance(originalConfig));
            return axiosInstance(originalConfig);
          } catch (err) {
            console.error("Refresh Token Not Working, Redirecting to homepage")
            console.error(err)
            store.dispatch('auth/logout');
            router.push('/')
            return Promise.reject(err);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};

export default setup;