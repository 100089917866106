export const navigation = {
    namespaced: true,

    state: {
        version: null,
    },

    getters: {
       
        version(state){
            return state.version
        },
        
       
    },

    actions: {
        version({commit}, version){
            commit('version', version)
        },

      
    },
    mutations: {
        version(state, version){
            state.version = version
        },

       
    }
}