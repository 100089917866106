import { createStore } from 'vuex'
import { navigation } from './navigation.module';
import {auth} from './auth.module';
import {stats} from './stats.module';


export default createStore({
  modules: {
    navigation, auth, stats
  },
})