import TerreEtPierreApiService from "../services/terreetpierreapi.service";
import StorageService from "@/services/storage.service";
import JwtParser from "@/services/jwtparser.service";


const user = StorageService.getUser()
const initialState = user
    ? { loggedIn: true, user: user  }
    : { loggedIn: false, user: null };

export const auth = {
    namespaced: true,
    state: initialState,

    getters: {
        isLogged: (state) => state.loggedIn,
        isAdmin: (state) => {
            if (state.user && state.user.token) {
                let jwtTokenObj = JwtParser.getJson(state.user.token)
                return jwtTokenObj.role == "admin"
            }
            return true;
        },
        currentUser: (state) => state.user,
        sessionExpired: (state) => {
            if (state.user && state.user.token) {
                let token = JwtParser.getJson(state.user.token)
                return token.exp >= new Date()
            }
            return true;
        },
    },

    actions: {
        async login({ commit }, user) {
            try {
                let logResponse = await TerreEtPierreApiService.login(user)
                commit('loginSuccess', logResponse.data);
                return Promise.resolve(user);
            } catch (error) {
                commit('loginFailure');
                return Promise.reject(error);
            }
        },
    
        logout({ commit }) {
            TerreEtPierreApiService.logout();
            commit('logout');
        },

        refreshToken({ commit }, token) {
            commit('refreshToken', token);
        },

        updateStore({ commit }, newUser) {
            commit ('updateLocalUser', newUser)
        }
    },

    mutations: {
        loginSuccess(state, user) {
            StorageService.update(user);
            state.loggedIn = true;
            state.user = user;
        },
        
        loginFailure(state) {
            state.loggedIn = false;
            state.user = null;
        },
        
        logout(state) {
            StorageService.reset();
            state.loggedIn = false;
            state.user = null; 
        },
        
        refreshToken(state, token) {
            StorageService.updateLocalAccessToken(token);
            state.loggedIn = true;
            state.user = { ...state.user, token: token };
        },
        
        updateLocalUser(state, newUser) {
            state.user = newUser
        }
    }
};
