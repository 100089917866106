import api from './api';

class TerreEtPierreApiService {
  /***
      Authentication
  ***/
  login = (user) => api.post('/authentication', user, { withCredentials: true })
  logout = () =>  api.post('/authentication/logout', { withCredentials: true })
  refresh = () =>  api.post('/authentication/refresh', { withCredentials: true })

  // Users
  getUserList(){
    return api.get('/users/');
  }
  getUserDetails(userId){
    return api.get(`/users/${userId}`)
  }
  getUserStatus(userId){
    return api.post(`/users/switchrole/${userId}`, {});
  }
  

 
  updateProfile(userid,dataToSend) {
    return api.post(`/users/profile/update/${userid}` , dataToSend);
  }
  
  createNewUser(dataToSend) {
    return api.post(`/users/create`, dataToSend)
  }
  getUserSummary() {
    return api.get('/summary/');
  }

  disableOrEnable(userid) {
    return api.post(`/users/status/switch/${userid}`,{})
  }
  

  // feedback
  sendFeedBack(feedback) {
    return api.post(`/feedback/`, feedback);
  }
  deleteFeedBack(feedbackId) {
    return api.post(`/feedback/delete/${feedbackId}`, {});
  }
  getFeedBacks(query, userid) {
    if(userid == null) userid = ""
    return api.post(`/feedback/${userid}`, query);
  }
  
  // Password
  sendTemporaryPassword(email) {
    return api.post(`/password/forget/step/1`, `"${email}"`)
  }
  sendNewPassword(request) {
    return api.post(`/password/forget/step/2`, request)
  }
  changePasswordRequest(request) {
    return api.post(`/password/`, request);
  }
  regenerateNewPassword(userid) {
    return api.post(`/password/${userid}/generate`, {});
  }
  
  
  // Profile Picture
  sendProfilePicture(files){
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    return api.post(`/profilepicture/`, files, {headers: headers})
  }
  getProfilePicture(userId){
    return api.get(`/profilepicture/${userId}`)
  }
  
  // objectives
  getObjectiveDetails(objectiveId) {
    return api.get(`/objectives/${objectiveId}`);
  }
  achieveOne(objectiveId, value) {
    return api.post(`/objectives/${objectiveId}`, value);
  }
 
  
  // mandates
  addMandate(typeid) {
    return api.post(`/mandates/${typeid}`, {});
  }
  getMandateCountbyType(typeid) {
    return api.get(`/mandates/type/${typeid}/count`)
  }
  
  reduceNumberOfMandates(actionid,typeid, amount) {
    var actionName = (actionid == 1) ? 'sold' : 'lost';
    return api.post(`/mandates/${actionName}/${typeid}`, amount);
  }

  ///
  getWeekrates = (userIds) => api.post(`/summary/weekrate/`, userIds);
  
  

  //Get all stats for recap table
  getMandatesStats = (query) => api.post(`/Statistics/mandates`, query)
  getObjectivesStats = (query) =>  api.post(`/Statistics/objectives`,query)
  

  getConfiguration() {
    return api.get(`/Statistics/configuration`);
  }


  // Valeurs à atteindre propre à chaque user
  getUserObjectives(userId){
    return api.get(`/objectives/user/${userId}`);
  }
  sendNewGoalValue(dataToSend) {
    return api.post(`/objectives/create`, dataToSend)
  }


  
}

export default new TerreEtPierreApiService();